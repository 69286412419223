<template>
  <SidePopup
    :title="title"
    v-on:close="onClose"
    @submit="onSubmit"
    routeback="/fornecedor/marketing/link-acao"
  >
    <FormField
      label="Nome da ação"
      v-model="form.nome"
      :validation="$v.form.nome"
    />
    <FormField
      label="URL amigável"
      v-model="form.slug"
      help="URL usada para o acionamento da ação. Dê preferência a URLs curtas e diretas que facilitem a visualização e digitação"
      v-stringscaped
    />
    <FormSelect
      label="Ação"
      v-model="form.tipo_acao"
      :options="actionOptions"
      :validation="$v.form.tipo_acao"
    />
    <p>
      <b>Instruções da ação</b> (<a
        href="https://pt.wikipedia.org/wiki/Markdown"
        target="_blank"
        >dicas de formatação</a
      >)
    </p>
    <FormMarkdown v-model="form.descricao" />
    <small
      v-if="$v.form.descricao.$invalid"
      class="font-weight-bold error--text"
    >
      As intruções da ação são obrigatórias.
    </small>
    <p>
      <b>Instruções quando a ação estiver inativa</b> (<a
        href="https://pt.wikipedia.org/wiki/Markdown"
        target="_blank"
        >dicas de formatação</a
      >)
    </p>
    <FormMarkdown v-model="form.descricao_inativo" />
    <br />
    <template v-if="form.tipo_acao === 'CARRINHO_COMPRAS'">
      <LineDivider><h4>Produtos</h4></LineDivider>
      <LinkAcaoProduto v-model="form.conteudo" />
    </template>
    <FormSwitch v-model="form.ativo" label="Ativar ação" />
    <template v-slot:buttons>
      <FormButton text="Salvar" submit type="submissao" />
    </template>
  </SidePopup>
</template>

<script>
import FormField from '@/components/form/FormField'
import FormSwitch from '@/components/form/FormSwitch'
import FormMarkdown from '@/components/form/FormMarkdown'
import FormSelect from '@/components/form/FormSelect'
import SidePopup from '@/components/SidePopup'
import LinkAcaoProduto from './LinkAcaoProduto'
import FormButton from '@/components/form/FormButton'
import LineDivider from '@/components/LineDivider'
import { required } from 'vuelidate/lib/validators'
import {
  LINK_ACAO_CREATE,
  LINK_ACAO_UPDATE,
  LINK_ACAO_LOAD
} from '@/store/actions/link-acao'

export default {
  name: 'LinkAcaoForm',
  components: {
    FormField,
    SidePopup,
    FormSwitch,
    FormMarkdown,
    FormSelect,
    LinkAcaoProduto,
    LineDivider,
    FormButton
  },
  data: () => ({
    mode: 'new',
    form: {
      nome: '',
      slug: '',
      descricao: '',
      ativo: true,
      tipo_acao: 'CARRINHO_COMPRAS',
      conteudo: [],
      descricao_inativo: ''
    },
    actionOptions: [
      {
        text: 'Carrinho de compras',
        value: 'CARRINHO_COMPRAS'
      }
    ]
  }),
  validations: {
    form: {
      nome: { required },
      descricao: { required }
    }
  },
  computed: {
    title() {
      return this.mode === 'edit' ? 'Editar link de ação' : 'Novo link de ação'
    }
  },
  created() {
    const idlinkacao = this.$route.params.idlinkacao
    if (idlinkacao !== 'novo') {
      this.mode = 'edit'
      this.getData(idlinkacao)
    }
  },
  methods: {
    getData(idlinkacao) {
      this.isLoading = true
      this.$store
        .dispatch(LINK_ACAO_LOAD, idlinkacao)
        .then(resp => {
          this.form = {
            ...this.form,
            ...resp,
            descricao: resp.descricao || '',
            descricao_inativo: resp.descricao_inativo || ''
          }
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
          this.onClose()
        })
    },
    onClose(routeBack = false) {
      this.isLoading = false
      this.$emit('close')
      if (routeBack) {
        this.$router.replace('/fornecedor/marketing/link-acao')
      }
    },
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return
      this.isLoading = true
      const acao = {
        ...this.form,
        tipo_acao: this.form.tipo_acao || 'CARRINHO_COMPRAS'
      }
      if (this.mode === 'edit' && acao.idlinkacao) {
        this.$store
          .dispatch(LINK_ACAO_UPDATE, acao)
          .then(() => {
            this.$vueOnToast.pop(
              'success',
              'Link de ação atualizado com sucesso'
            )
            this.onClose(true)
          })
          .catch(() => (this.isLoading = false))
      } else {
        this.$store
          .dispatch(LINK_ACAO_CREATE, acao)
          .then(() => {
            this.$vueOnToast.pop('success', 'Link de ação criado com sucesso')
            this.onClose(true)
          })
          .catch(() => (this.isLoading = false))
      }
    }
  }
}
</script>

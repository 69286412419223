<template>
  <div>
    <v-row v-for="item in produtos" :key="item.id">
      <v-col class="d-flex justify-center" cols="12" xs="12" sm="1" md="1">
        <FormButton icon type="perigo" @click="remove(item.id)"
          ><v-icon size="18">fas fa-times</v-icon></FormButton
        >
      </v-col>
      <v-col cols="12" xs="12" sm="5" md="5">
        <FormTags
          :placeholder="getPlaceHolder(item)"
          label="Produto"
          v-model="item.produto"
          :endpoint="endpoint"
          queryname="filter"
          keyid="id_produto_erp"
          keyvalue="text"
          :multiple="false"
          :searchWithSpaces="true"
          @change="onChange"
        >
          <template v-slot:item="{ item }">
            <div class="d-flex flex-column align-start">
              <p style="margin-bottom:0!important">{{ item.nome }}</p>
              <small v-if="item.id_produto_erp"
                >Cód. interno: {{ item.id_produto_erp }}</small
              >
              <small v-if="item.codigobarras"
                >Cód. barras:{{ item.codigobarras }}</small
              >
            </div>
          </template>
        </FormTags>
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="6">
        <FormField
          class="form-inline-item"
          label="Quantidade"
          v-model="item.quantidade"
          @change="onChange"
        />
      </v-col>
    </v-row>
    <v-row justify="center">
      <FormButton type="primario" @click="add" text="Adicionar produto" />
    </v-row>
  </div>
</template>

<script>
import FormField from '@/components/form/FormField'
import FormButton from '@/components/form/FormButton'
import { mapGetters } from 'vuex'
import * as _ from 'lodash'
import FormTags from '@/components/form/FormTags'

export default {
  name: 'LinkAcaoProdutos',
  components: { FormTags, FormField, FormButton },
  props: {
    value: {
      type: Array,
      default: () => [],
      required: true
    },
    validation: {
      type: Object,
      default: () => null,
      required: false
    }
  },
  data: () => ({
    produtos: [
      {
        produto: null,
        quantidade: 1
      }
    ]
  }),
  computed: {
    ...mapGetters(['getFornecedorId']),
    endpoint() {
      return `/api/v1/produtos/s2format-simple?idfornecedor=${this.getFornecedorId}&tipoProduto=TODOS`
    }
  },
  watch: {
    value: {
      handler(newVal) {
        this.produtos = newVal
      }
    }
  },
  created() {
    if (this.value) {
      this.produtos = this.value
    }
  },
  methods: {
    getPlaceHolder(item) {
      return (
        _.get(item, 'produto.slug') ||
        'Pesquisa pelo nome, código interno ou código de barras'
      )
    },
    add() {
      this.produtos.push({
        produto: null,
        quantidade: 1,
        id: Math.random() * 10000
      })
    },
    remove(id) {
      this.produtos = this.produtos.filter(item => item.id != id)
      this.onChange()
    },
    onChange() {
      this.$emit('input', this.produtos)
      this.$emit('change', this.produtos)
    }
  }
}
</script>
